// Imports
// ------
import React from 'react';

// Component
// ------
function NotFoundPage() {
	return (
		<main>
			{/*  */}
			{/*  */}
			{/*  */}
			{/*  */}
			{/*  */}
			{/*  */}
		</main>
	);
}

export default NotFoundPage;
